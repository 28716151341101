<template>
    <div class="home" id="wrap">
      <Header />
      <Sidebar />
      <Main />
      <Navbar />
    </div>
  </template>
  
  <script>
  import Header from '@/components/common/Header'
  import Sidebar from '@/components/common/Sidebar'
  import Main from '@/components/dashboard/GuidePage'
  import Navbar from '@/components/common/Navbar.vue'

  
  export default {
    name: 'Home',
    components: {
      Header,
      Sidebar,
      Main,
      Navbar,
    },
    
    data(){
      return{
      }
    },
    mounted(){
    }
  }
  </script>
  