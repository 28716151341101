<template>
    <div class="section_right">
        <div class="section_right_inner">
            <div class="pt-6 pb-4">
                <h3 class="text-center mb-6 fw-bold text-gray-relative-900">{{title}}</h3>
                <div class="d-flex flex-wrap">
                    <div class="col-12 col-sm-6 mb-6 px-3" v-for="(item,index) in list1" :key="index">
                        <div class="flex-start-center mb-3">
                            <div class="w-px-30 h-px-30 lh-px-30 bg-primary text-white text-center rounded-circle flex-shrink-0 me-2">{{index+1}}</div>
                            <p class="lh-sm fw-bold text-gray-relative-800">{{item.desc}}</p>
                        </div>
                        <img :src="item.img" class="w-100 shadow">
                    </div>
                </div>
                <div class="mb-6 px-4">
                    <router-link :to="`/${this.$i18n.locale}/guide`" class="btn btn-primary w-100 ">{{$t("guide.101")}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        data(){
            return{
                title: "",
                list1: []
            }
        },
		mounted(){
            let lang = this.$i18n.locale;
            switch (this.$route.params.idx){
                case '1' : 
                this.title = this.$t("guide.1")
                this.list1 = [
                    {
                        img: require(`@/assets/img/guide/1-1_${lang}.png`),
                        desc: this.$t("guide.2")
                    },
                    {
                        img: require(`@/assets/img/guide/1-2_${lang}.png`),
                        desc: this.$t("guide.3")
                    },
                ]
                break;
                
                case '2' : 
                this.title = this.$t("guide.4")
                this.list1 = [
                    {
                        img: require(`@/assets/img/guide/2-1_${lang}.png`),
                        desc: this.$t("guide.17")
                    },
                    {
                        img: require(`@/assets/img/guide/2-2_${lang}.png`),
                        desc: this.$t("guide.5")
                    },
                    {
                        img: require(`@/assets/img/guide/2-3_${lang}.png`),
                        desc: this.$t("guide.6")
                    },
                ]
                break;

                case '3' : 
                this.title = this.$t("guide.7")
                this.list1 = [
                    {
                        img: require(`@/assets/img/guide/3-1_${lang}.png`),
                        desc: this.$t("guide.8")
                    },
                    {
                        img: require(`@/assets/img/guide/3-2_${lang}.png`),
                        desc: this.$t("guide.9")
                    },
                ]
                break;

                case '4' : 
                this.title = this.$t("guide.10")
                this.list1 = [
                    {
                        img: require(`@/assets/img/guide/4-1_${lang}.png`),
                        desc: this.$t("guide.11")
                    },
                    {
                        img: require(`@/assets/img/guide/4-2_${lang}.png`),
                        desc: this.$t("guide.12")
                    },
                ]
                break;

                default: 
                this.title = this.$t("guide.13")
                this.list1 = [
                    {
                        img: require(`@/assets/img/guide/5-1_${lang}.png`),
                        desc: this.$t("guide.14")
                    },
                    {
                        img: require(`@/assets/img/guide/5-2_${lang}.png`),
                        desc: this.$t("guide.15")
                    },
                    {
                        img: require(`@/assets/img/guide/5-3_${lang}.png`),
                        desc: this.$t("guide.16")
                    },
                ]
            }
            
        },
	}
</script>

<style lang="scss" scoped>

</style>