<template>
    <div class="section_right">     
        <div class="sub_top">
            <div class="sub_title flex-between-center">
                <div><i class="far fa-book-open text-primary"></i>{{$t("guide.100")}}</div>
            </div>
        </div>
        
        <div class="section_right_inner">
            <ul class="guide">
                <li class="rounded-3 mb-2 shadow-sm" v-for="(item, index) in guideList" :key="index">
                    <router-link class="p-3 d-block" :to="`guide/${item.link}`"><b class="text-primary pe-2">Q.</b><small>{{ item.title }}</small></router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
	export default {
        data(){
            return{
                guideList:[
                    {
                        link:'1',
                        title:this.$t("guide.1"),
                    },
                    {
                        link:'2',
                        title:this.$t("guide.4")
                    },
                    {
                        link:'3',
                        title:this.$t("guide.7")
                    },
                    {
                        link:'4',
                        title:this.$t("guide.10")
                    },
                    {
                        link:'5',
                        title:this.$t("guide.13")
                    }
                ]
            }
        },
		mounted(){
        },
	}
</script>

<style lang="scss" scoped>
.guide li{
    background-color: #fff;
}
.theme01 .guide li{
    background-color: #1f1f1f;
    a{
        color: #fff;
    }
}
</style>